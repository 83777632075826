@import "media";
@import "./root.scss";
@import "./mixins.scss";

* {
  box-sizing: border-box;
}

body {
  font-family: $main-font;
  margin: 0;
}
// Стилизация ползунка

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  margin-top: -2px;

  cursor: pointer;
}

input[type="range"]::-webkit-slider-runnable-track {
  height: 1px;
  background-color: $main;
  border-radius: 8px;
}

input[type="range"]::-moz-range-track {
  height: 1px;
  background-color: $main;
  border-radius: 8px;
}

input[type="range"]::-webkit-slider-thumb {
  background: #ffffff;
  border: 1px solid $gray;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;

  -webkit-appearance: none;

  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  background: #ffffff;
  border: 1px solid $gray;
  border-radius: 50%;

  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

h4 {
  font-weight: 400;
  font-size: 1.3125rem;

  color: $main;
}

button {
  cursor: pointer;
}

// Шрифты

@font-face {
  font-family: "HeliosExt";
  src: url("../fonts/heliosext.woff2") format("woff2");
  font-weight: 400;
}

@media (max-width: 768px) {
  input,
  textarea {
    font-size: 1rem;
  }
}
