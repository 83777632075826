@import "src/assets/style/root";
@import "src/assets/style/mixins";

.form__container {
  margin-top: 1.875rem;
}

.form__title {
  color: $main;
  font-family: "HeliosExt";
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 1.25rem;
}

.button__submit__wrapper {
  display: flex;
  justify-content: flex-end;
}

.input__wrapper {
  margin-bottom: 1.25rem;
}

.input__comment__wrapper {
  margin-bottom: 1.875rem;
}

.input__error {
  border: 1px solid $danger;
}

.input__error__text {
  padding-left: 2px;
  font-size: 0.75rem;
  margin-top: 3px;
  color: $danger;
}

.button__submit {
  @extend %square_button;
  height: 3.75rem;
  width: 100%;
  background-color: $main;
  color: $gray;
  font-size: 1rem;
  font-family: "HeliosExt";
  &:hover {
    background-color: $active;
  }
}

.policy {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.625rem;
  font-size: 0.875rem;

  p {
    color: $text-gray;
    margin-bottom: 3px;
  }

  a {
    color: $main;
  }
}
