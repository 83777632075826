@import "src/assets/style/root";
@import "src/assets/style/mixins";

.container {
  background-color: $main;
  color: #fff;
  min-height: 4rem;
  display: flex;
  align-items: center;
  padding: 0 20px;
  transition: all 0.3s ease-in;

  &.opened {
    background-color: #4b4c4c;
  }

  span {
    font-size: 12px;
    margin-left: 10px;
  }
}
