.sidebar {
  display: flex;
  overflow: hidden;
  max-height: calc(100vh - 4.375rem);
}

.sidetabs {
  background-color: #212223;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
}
