@import "src/assets/style/root";

.box {
  padding: 1.875rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid $gray;
  }
}

.box__title {
  font-family: "HeliosExt";
  font-style: 1rem;
  font-weight: 400;
}

.box__item__title {
  margin-top: 1.125rem;
  font-size: 0.875rem;
}

.gray {
  color: $text-gray;
  font-family: $main-font;
}
