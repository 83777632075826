@import "src/assets/style/root";
@import "src/assets/style/mixins";

.container {
  position: absolute;
  top: 30%;
  transition: all 0.3s ease-in;
  right: 0;
  z-index: 3;
  display: flex;
  align-items: center;

  &.hidden {
    transform: translateX(67%);
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.button__background {
  width: 4.375rem;
  height: 4.375rem;
  background-color: $main;
  border: none;
}

.button__background__text {
  font-size: 0.75rem;
  color: $gray;
  margin-top: 4px;
}

.button__swipe {
  width: 2rem;
  height: 6rem;
  background-color: $main;
  border-radius: 1.625rem;
  transform: translateX(55%);
  position: relative;

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 2rem;
    width: 1px;
    background-color: #eeeeee;
    opacity: 0.4;
  }

  &::after {
    left: 11px;
  }
  &::before {
    left: 6px;
  }
}

@media (min-width: 500px) {
  .button__location {
    display: none;
  }
}
