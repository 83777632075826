@import "src/assets/style/root";

.sidetab {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 7.5rem;
  min-height: calc((100vh - 4.375rem) / 13);
  min-height: 4.0625rem;
  padding: 0.5313rem 0;
  font-family: $main-font;
  background-color: $main;
  color: white;
  border: none;

  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover,
  &.active {
    background-color: $active;
  }

  &__icon {
    margin-bottom: 0.5rem;
  }

  &__title {
    // padding: 0 0.625rem;
    max-width: 6.25rem;
  }
}
