@import "src/assets/style/root";

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.inner {
  padding: 1.5rem 1.25rem 1.25rem 1.25rem;
}

.title {
  font-family: "HeliosExt";
  margin-bottom: 1.25rem;
}

.tabs {
  display: flex;
  flex-direction: column;
}

.tab__item {
  width: fit-content;
  border: none;
  font-family: "HeliosExt";
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  transition: color 0.3s ease-in;
  color: $active-gray;

  &.active {
    color: $main;
    text-decoration: underline;
  }
}
