@import "src/assets/style/root";
@import "src/assets/style/mixins";
.container {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.images {
  display: flex;
}

.image__container {
  width: 50%;
  height: calc(100vh - 4.375rem);
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 62%;
  }
}

.button__reset {
  width: 4.375rem;
  height: 4.375rem;
  background-color: $main;
  border: none;
  position: absolute;
  z-index: 4;
  left: 0;
  top: 0;
  &:hover {
    background-color: $active;
  }
}

.background__button__group {
  position: absolute;
  z-index: 5;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
}

.button__background {
  width: 4.375rem;
  height: 4.375rem;
  background-color: $main;
  border: none;
  &:hover {
    background-color: $active;
  }
}

.button__reset__text {
  font-size: 0.75rem;
  color: $gray;
  margin-top: 4px;
}

.footer__buttons {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  display: flex;
}

.button__order__modal {
  @extend %square_button;
  background-color: $main;
  color: $gray;
  width: 50%;
  height: 3.375rem;
  font-size: 1rem;
  font-family: "HeliosExt";
  &:hover {
    background-color: $active;
  }
}

.footer__price {
  width: 50%;
  height: 3.375rem;
  color: $main;
  font-size: 1rem;
  font-family: "HeliosExt";
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $main;
  background-color: #fff;

  span {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 1.25rem;
  }
}

.loader__container {
  width: 100%;
  padding-top: 12.5rem;
  display: flex;
  justify-content: center;
}

.custom__loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 8px solid;
  border-color: #e4e4ed;
  border-right-color: #212223;
  animation: s2 1s infinite linear;
}
@keyframes s2 {
  to {
    transform: rotate(1turn);
  }
}

.wall__menu,
.floor__menu {
  position: absolute;
  z-index: 5;
  right: 0;
  top: 0;
  background-color: #fff;
  width: 14.875rem;
  height: 23.75rem;
  overflow-y: auto;
  box-shadow: -4px 3px 4px 0px rgba(61, 86, 105, 0.2);

  &::-webkit-scrollbar {
    width: 0;
  }
}
