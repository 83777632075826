.backdrop {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  height: 100vh;
  height: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 20;
}

.container {
  &__scroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
    top: 0;
  }

  &__flex {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.content {
  background-color: #fff;
  min-width: 25rem;
  min-height: 100vh;
  padding: 1.5rem 3rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.success__text {
  font-size: 1.375rem;
}

.success__icon {
  margin-left: 0.625rem;
}
