@import "src/assets/style/root";

.button__download {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0 1.5rem;

  &:hover {
    background-color: $gray;
  }
}

.button__download_text {
  margin-right: 0.625rem;
}

@media (max-width: 768px) {
  .button__download {
    padding: 0 0.5rem;
  }

  .button__download_text {
    display: none;
  }
}
