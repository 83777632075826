.dropdown__container {
  position: relative;
  width: fit-content;
}

.dropdown__content {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid black;
  z-index: 5;
}
