@import "src/assets/style/root";

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.locks__inner {
  padding: 1.5rem 1.25rem 1.25rem 1.25rem;
}

.locks__title {
  font-family: "HeliosExt";
  margin-bottom: 1.25rem;
}

.lock__list {
  display: grid;
  grid-template-columns: repeat(2, 9.0625rem);
  grid-gap: 0.9375rem 0.9375rem;
}

.lock__item {
  width: 100%;
}

.lock__img {
  &.locked {
    cursor: not-allowed;
  }

  cursor: pointer;
  width: 100%;
  position: relative;

  .lock__image {
    width: 100%;
  }

  .lock__image.locked {
    opacity: 0.6;
  }
}

.check {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1rem;
  height: 1rem;
}

.lock__title {
  margin-top: 0.625rem;
  font-size: 0.875rem;
  color: $text-gray;
}

.additional__locks {
  margin-top: 2.25rem;
}

.additional__locks__title {
  font-family: "HeliosExt";
  margin-bottom: 1.25rem;
}

.button__reset {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  border-radius: 1rem;
  min-height: 2rem;
  border: none;
  background-color: rgb(199, 195, 195);
  padding: 0 1rem;

  img {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
    position: relative;
    margin-top: 2px;
  }
}
