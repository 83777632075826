@import "src/assets/style/mixins";

.input__inner {
  position: relative;
}

.input__text {
  @extend %input;
  width: 100%;
}

.with__start_icon {
  padding-left: 3.125rem;
}

.icon__start {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
}
