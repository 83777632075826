.door__container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding-top: 3rem;
  padding-bottom: 6rem;
  display: flex;
  justify-content: center;
  opacity: 1;
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 0.3s ease-in;

  &.hide {
    opacity: 0;
  }
}

.door__inner {
  width: 14.5rem;
  height: 100%;
}

.door {
  width: 100%;
  height: 100%;
}

.test {
  color: #8d8f91;
  background-color: #c0c5c5;
}
