@import "src/assets/style/root";

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.info__inner {
  padding: 1.5rem 1.25rem 1.25rem 1.25rem;
}

.info__title {
  font-family: "HeliosExt";
  padding-bottom: 1.25rem;
  border-bottom: 1px solid $gray;
}
