@import "src/assets/style/root";

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.handles__inner {
  padding: 1.5rem 1.25rem 1.25rem 1.25rem;
}

.handles__title {
  font-family: "HeliosExt";
  margin-bottom: 1.25rem;
}

.tab__list {
  display: flex;
  gap: 1rem;
}

.tab__item {
  font-family: "HeliosExt";
  font-size: 0.875rem;
  color: $active-gray;
  cursor: pointer;
  transition: color 0.3s ease-in;

  &.active {
    color: $main;
    text-decoration: underline;
  }
}

.tab__content {
  margin-top: 1.3125rem;
}

.color__list {
  display: grid;
  grid-template-columns: repeat(2, 9.0625rem);
  grid-gap: 0.9375rem 0.9375rem;
}

.color__item {
  width: 100%;
}

.color__img {
  &.locked {
    cursor: not-allowed;
  }

  cursor: pointer;
  width: 100%;
  position: relative;

  .color__image {
    width: 100%;
  }
  .color__image.locked {
    opacity: 0.6;
  }
}

.color__check {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1rem;
  height: 1rem;
}

.color__title {
  margin-top: 0.625rem;
  font-size: 0.875rem;
  color: $text-gray;
}
