@import "src/assets/style/root";

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.models__inner {
  padding: 1.5rem 1.25rem 1.25rem 1.25rem;
}

.models__title {
  font-family: "HeliosExt";
  margin-bottom: 1.25rem;
}

.filter__button {
  padding: 0.75rem 0.75rem;
  width: 100%;
  min-height: 3.125rem;
  border: 1px solid $gray;
  margin-bottom: 1.25rem;
}

.filter__button__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter__button__title {
  color: $text-gray;
}

.model__list {
  display: grid;
  grid-template-columns: repeat(2, 9.0625rem);
  grid-gap: 0.9375rem 0.9375rem;
}

.model__item {
  width: 100%;
}

.model__img {
  cursor: pointer;
  width: 100%;
  position: relative;

  .model__image {
    width: 100%;
  }

  .model__image.locked {
    opacity: 0.6;
  }
}

.check {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1rem;
  height: 1rem;
}

.model__title {
  margin-top: 0.625rem;
  font-size: 0.875rem;
  color: $text-gray;
}

@media (max-width: 430px) {
  .model__list {
    justify-content: space-between;
  }
}
