@import "./root.scss";

%square_button {
  width: 4.375rem;
  height: 4.375rem;
  border: none;

  cursor: pointer;
}

%close-button {
  border: none;
  height: 1.5rem;
  width: 1.5rem;

  cursor: pointer;
}

%input {
  padding: 0.9375rem 1rem;
  border: 1px solid $gray;
  background: none;
  outline: none;
}

%range-output {
  @extend %input;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  color: $text-gray;
  font-size: 1rem;
}
