@import "src/assets/style/root";

.dropdown__container {
  position: relative;
  width: fit-content;
}

.dropdown__toggle {
  cursor: pointer;
  width: 4.375rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $gray;
  }
}

.dropdown__content {
  width: 12.5rem;
  padding: 0.625rem 0 0.625rem 0;
  position: absolute;
  top: 0;
  right: 4.25rem;
  background-color: #f2f2f2;
  border-radius: 3px;
  z-index: 6;
}

.social__button {
  display: flex;
  align-items: center;
  gap: 0.4375rem;

  p {
    font-size: 0.875rem;
  }
}

.social__button__wrapper {
  width: 100%;
  padding: 0.5rem 1.25rem;
  transition: 0.2s background-color ease-in;

  &:hover {
    background-color: #d6d4d4;
  }
}

@media (max-width: 768px) {
  .dropdown__content {
    top: 4.375rem;
    right: 0;
  }
}
