@import "src/assets/style/mixins";
@import "src/assets/style/root";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.375rem;
  width: 100vw;
  box-shadow: 0px 13px 12px 0px rgba(34, 60, 80, 0.2);
  padding-left: 1.6875rem;
  z-index: 10;
  position: relative;

  &__logo {
    height: 20px;
  }

  &__menu {
    display: flex;
  }

  &__localization {
    @extend %square_button;
    background-color: transparent;
  }

  &__share {
    @extend %square_button;
    background-color: transparent;
  }

  &__localization:hover,
  &__share:hover {
    background-color: $gray;
  }
}

.button__localization {
  @extend %square_button;
  background-color: $main;
  color: $gray;
}

@media (max-width: 768px) {
  .header {
    padding-left: 1rem;
  }
}
