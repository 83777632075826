@import "src/assets/style/root";

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.inner {
  padding: 1.5rem 1.25rem 1.25rem 1.25rem;
}

.title {
  font-family: "HeliosExt";
  margin-bottom: 1.25rem;
}

.height__wrapper {
  margin-bottom: 2.25rem;
}

.label {
  font-family: "HeliosExt";
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .container {
    min-height: 37rem;
  }
}
