@import "src/assets/style/root";
@import "src/assets/style/mixins";

.backdrop {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  height: 100vh;
  height: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 20;
}

.container {
  &__scroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
    top: 0;
  }

  &__flex {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.content {
  min-width: 100vw;
  min-height: 100vh;
  background-color: $main;
  padding-bottom: 7rem;
}

.button__close {
  @extend %square_button;
  width: 90%;
  height: auto;
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  height: 70px;
  border-radius: 3.125rem;
  background-color: #393a3b;
  color: #e3e3e3;
  font-family: "HeliosExt";
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
