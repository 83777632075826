@import "src/assets/style/mixins";

.textarea {
  @extend %input;
  width: 100%;
  resize: none;
  height: 9.375rem;
  padding: 0.75rem 1.875rem 0.75rem 1rem;

  &::-webkit-scrollbar {
    width: 0;
  }

  &::placeholder {
    font-family: $main-font;
  }
}
