@import "src/assets/style/root";
@import "src/assets/style/mixins";

.sideCover {
  height: calc(100vh - 4.375rem);
  width: 21.875rem;
  background-color: $darkgray;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
}
