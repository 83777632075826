@import "src/assets/style/root";

.backdrop {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  height: 100vh;
  height: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 20;
}

.container {
  &__scroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
    top: 0;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__flex {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.content {
  background-color: #fff;
  min-height: 100vh;
  min-width: 30rem;
  padding: 1.875rem 1.875rem;
  position: relative;
}

.button__close {
  position: absolute;
  top: 1.875rem;
  right: 1.875rem;
  cursor: pointer;
}

.title {
  color: $main;
  font-family: "HeliosExt";
  font-weight: 400;
  font-size: 1.875rem;
  margin-bottom: 1.9375rem;
}

.desc {
  font-size: 1rem;
  line-height: 1.5rem;
  color: $text-gray;
  margin-bottom: 1.25rem;
}
.accordion__wrapper {
  // margin-bottom: 1.875rem;
  padding: 1.25rem 0;
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
}

.accordion__trigger {
  cursor: pointer;
  font-size: 1.3125rem;
  color: $main;
  font-family: "HeliosExt";
  font-weight: 400;
  position: relative;
  display: block;

  &::before,
  &::after {
    content: "";
    width: 2px;
    height: 0.75rem;
    position: absolute;
    top: calc(50% - 3px);
    right: 1.5rem;
    background-color: $main;
    transition: transform 0.3s ease-in;
  }

  &::after {
    transform: rotate(90deg);
  }

  &_open {
    &::before {
      transform: rotate(90deg);
    }
  }
}

@media (max-width: 768px) {
  .content {
    min-height: 100vh;
    min-width: 0;
    width: 100%;
  }
}
